html {
  overflow-x: hidden;
  overflow-y: auto;
  max-width: 100%;
}
body {
  scroll-behavior: smooth;
}

.pb-6{
  padding-bottom: 6rem;
}

.bgc-dark {
  background-color: black;
}

.bgc-white {
  background-color: white;
}

.navbar-brand {
  font-size: 2.5rem;
}

.brand{
  width: 120px;
}

.brand-small{
  width: 50px !important;
}

.navbar-nav li {
  font-size: large;
  position: relative;
  text-decoration: none;
  color: white;
}

.navbar-nav li .main-nav-active {
  content: '';
  display: block;
  width: auto;
  border-bottom: 1px solid white;
  margin: 0 auto;
}

.nav-link {
  color: white !important;
}

.dropdown-item{
  padding:1.25rem 2rem;
}

.dropdown-item:hover{
  background-color: #ca8d2f;
}
.dropdown-item.active, .dropdown-item:active {
  color: black;
  text-decoration: none;
  background-color: white;
}

.main {
  background-image: url(../src/img/services/Head.png);
  background-size: cover;
  height: 95vh;
  width: 100%;
  background-position: center;
}

#main-text{
  padding-top:70%;
  font-size: 70px;
  color:white;
  margin-left: 20%;
  font-family: 'Montserrat', sans-serif;
  font-weight: 200;
}

#secondary-text{
  padding-top:70%;
  font-size: 70px;
  color:white;
  float: right;
  font-family: 'Montserrat', sans-serif;
  font-weight: 200;
}

@media only screen and (max-width: 600px) {
  #secondary-text, #main-text{
    display: none;
  }
}

@supports (font-variation-settings: "wght" 700){
  #bold{
    font-weight: 700;
  }
}

.main-text-white{
  color: white;
  font-family: 'Geneva', sans-serif;
  font-size: 18px;
  padding-top: 2%;
}

#section07 a {
  padding-top: 80px;
  scroll-behavior: smooth;
}
#section07 a span {
  position: absolute;
  top: 0;
  left: 50%;
  width: 35px;
  height: 35px;
  margin-left: -12px;
  border-left: 3px solid #ca8d2f;
  border-bottom: 3px solid #ca8d2f;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-animation: sdb07 2s infinite;
  animation: sdb07 2s infinite;
  opacity: 0;
  box-sizing: border-box;
}
#section07 a span:nth-of-type(1) {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
#section07 a span:nth-of-type(2) {
  top: 16px;
  -webkit-animation-delay: 0.15s;
  animation-delay: 0.15s;
}

@media only screen and (max-width: 600px) {

  #section07{
    display: none;
  }
}

@-webkit-keyframes sdb07 {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes sdb07 {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@media (min-width: 1920px){
  .container-fluid .col-md-7 {
    flex: 0 0 45.333333%;
    max-width: 58.333333%;
  }
}


.jumbotron-services {
  font-family: Lato;
  font-weight: 100;
}

.jumbotron-services h3 {
  letter-spacing: 5px;
}

.offset-md-3 {
  margin: auto !important;
  margin-left: auto !important;
}

.card {
  margin: 0 auto; /* Added */
  float: none; /* Added */
  margin-bottom: 80px; /* Added */
}

.card-title{
  font-family: 'Lato', sans-serif;
  letter-spacing: 3px;
}

.card .img-square-wrapper {
  border-right: 1px solid #ca8d2f;
  max-width: 50%;
  /*min-width: 40%;*/
}

@media only screen and (min-width: 600px) {
  .card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card h5{
  font-size: 1.9rem;
  margin-left: auto;
  margin-right: auto;
  padding-top: 15%;
}

@media only screen and (min-width: 1024px) {
  .card h5{
    padding-top:12%;
  }
}


@media only screen and (max-width: 768px) {
  .card h5{
    font-size: 1.2rem;
  }

  .card-body .btn-warning{
    margin-top: 5%;
    font-size: 0.75rem;
  }

  .card-body .btn-readmore{
    font-size: 0.75rem;
    margin-top:5%;
  }

}


.card-horizontal {
  display: flex;
}

.card-body {
  color: white;
}

.btn {
  letter-spacing: 2px;
}

.btn-warning:hover {
  color: #ca8d2f;
  background-color: black;
}

.btn-warning2:hover {
  color: #ca8d2f;
  background-color: black;
}

.btn-warning {
  color: white;
  background-color: #ca8d2f;
  border-color: #ca8d2f;
  border-radius: 0;
  font-size: 1.3rem;
  font-family: 'Lato', sans-serif;

}

.btn-readmore{
  border-color: #ca8d2f;
  color: white;
  border-radius: 0;
  font-size: 1.3rem;
  font-family: 'Lato', sans-serif;

}

.btn-readmore:hover{
  color: #ca8d2f;
}

.btn-warning2 {
  color: white;
  background-color: #ca8d2f;
  border-color: #ca8d2f;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  letter-spacing: 9px;
}

.centered2{
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  letter-spacing: 9px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 900;
}

.centeredsmall{
  position: absolute;
  top: 52%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  letter-spacing: 1px;
  
}

.service {
  background-image: url(../src/img/services/Headerblack.webp);
  background-size: cover;
  background-position: center;
  vertical-align: middle;
  height: 90vh;
  width: 100%;
}

.services-text {
  color: white;
  font-family: "Geneva", sans-serif;
}

.services-text li{
  font-size: 1.2rem;
}

.service-price span{
  margin-top: 5%;
  font-size: 1.7rem;
  border: 1px solid #ca8d2f;
  padding: 10px;
}

.shop-text h1,
h3 {
  color: #ca8d2f;
}

.services-text h1{
  color: #ca8d2f;
  letter-spacing: 3px;
  font-weight: 800;
  font-family: "Montseraat", sans-serif;

}

.services-text h6 {
  color: white;
  font-style: italic;
}

.services-text .btn {
  border-radius: 0%;
  padding: 0.375rem 2rem;
  font-style: italic;
  font-size: 20px;
}

.jumbotron {
  padding-top: 0 !important;
}

.jumbotron-service .centered {
  top: 60%;
}

hr {
  border: 0;
  clear: both;
  display: block;
  width: 96%;
  background-color: #585353;
  height: 1px;
}

.services-text li {
  padding-bottom: 5%;
}

li::marker {
  color: #ca8d2f;
}

.booking-policy p {
  color: #ca8d2f;
  margin-bottom: 0%;
  float: center;
}

/*ABOUT*/
#anca-about{
  max-width: 80%;
  height: auto;
}
@media screen and (min-width: 800px) {
  #anca-about{
    margin-right: -150px;
  }
}

#anca-signature{
  max-width: 40%;
  height: auto;
  float: right;
}

.bold-gold{
  color: #ca8d2f;
  font-weight: bold;
}
.container-about{
  padding: 5%;
  max-width: 950px;

}
.container-about p{
  color: white;
  font-family: 'Lato', sans-serif;
  font-family: 'Noto Sans TC', sans-serif;
}

.about-txt{
  letter-spacing: 5px;
  font-family: 'Lato', sans-serif;
  font-family: 'Noto Sans TC', sans-serif;
}

.about-upper-text{
  font-family: 'Lato', sans-serif;
  font-family: 'Noto Sans TC', sans-serif;
}
.col-description a{
  padding-right: 5%;
  
}

.img-border{
  border: 2px solid #ca8d2f;
}

.img-border2{
  border: 5px solid #ca8d2f;
}


.gold-text{
  color: #ca8d2f;
  font-size: 2.5rem;
}

.btn-warning-about{
  border: 1px solid #ca8d2f;
  background-color: #ca8d2f;
  color: white;
  border-radius: 0px;
}

.btn-warning-about:hover{
  border: 1px solid #ca8d2f;
  background-color: black;
  color: #ca8d2f !important;
  border-radius: 0px;
}

.btn-warning-about2{
  border: 1px solid #ca8d2f;
  background-color: black;
  color: white;
  border-radius: 0px;
}

.btn-warning-about:hover{
  color: black;
}

.btn-warning-about2:hover{
  color: #ca8d2f;
}

blockquote {
  font-family: Georgia, serif;
  position: relative;
  margin: 0.5em;
  padding: 0.5em 2em 0.5em 3em;
  color: #ca8d2f;
}


blockquote:before {
  font-family: Georgia, serif;
  position: absolute;
  font-size: 6em;
  line-height: 1;
  top: 0;
  left: 0;
  content: "\201C";
}
blockquote:after {
  font-family: Georgia, serif;
  position: absolute;
  float:right;
  font-size:6em;
  line-height: 1;
  right:0;
  bottom:-0.5em;
  content: "\201D";
}

blockquote p{
  color: white;
  font-size: 1.7rem;
}

.noborder{
  list-style: none !important;
}

/*Shop*/

.shop-card{
  color: black;
  font-family: 'Trebuchet MS', sans-serif;
  font-weight:300;
  border: 2px solid #ca8d2f;
}

.shop-card li{
  padding: 1rem;
}

.shop-card h6{
  font-weight: bold;
  color: #ca8d2f;
}

.nav-shop .nav-tabs{
  border-bottom: none;
}


.nav-shop .nav-tabs li{
  padding-right: 5%;
}

.shop-price{
  color: white;
}

.shop-desc{
  color: white;
}


.nav-shop .nav-tabs .nav-link.active, .nav-tabs .nav-item.show{
  color: #ca8d2f !important;
  font-weight: bold;
  border: none;
  background-color: transparent;
  /*border-bottom: 2px solid #ca8d2f;*/
}

.nav-shop .nav-link.active:after {
  content: "";
  display: block;
  height: 30px;
  background-image: url(../src/img/shop/Elem.png);
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: 20px;
}

.nav-shop .nav-link{
  color: #7e7a7a  !important;
  font-weight: bold;
  border: none;
  background-color: transparent;
}

/* 
.tab-pane.active{
  color: #ca8d2f;
} */

.shop-btn{
  border-radius: 0%;
  padding: 0.375rem 2rem;
  font-style: italic;
  font-size: 20px;
}

.shop-btn:hover{
  background-color: black;
  color: #ca8d2f;
}

.shop-phone{
  margin: 0px;
}



.hshop{
  color:#ca8d2f;
  margin-top: 5%;
  text-align: center;
}

.main-nav-active-shop{
  color: #ca8d2f !important;
  content: '';
  display: block;
  width: auto;
  border-bottom: 1px solid #ca8d2f; /*USE SYMBOL INSTEAD OF THIS*/
  margin: 0 auto;
}

.container-shop-links a:hover{
  color: #ca8d2f;
}
/*Connect*/
form{
  padding-top: 5%;
  padding-bottom: 5%;
  padding-left: 10%;
  padding-right: 10%;
  border: 2px solid #ca8d2f;
  border-radius: 10px;
}

.container-connect{
  padding-top:5% !important;
  padding-bottom: 5% !important;
}

.container-connect a{
  color: #ca8d2f;
}

.form-group{
  color: white;
}

/*Events*/
.events{
  background-image: url(../src/img/events/Header-events-l.webp);
  background-size: cover;
  background-position: center;
  height: 70vh;
  width: 100%;
}

.container-events{
  max-width: 1000px;
  margin-top: -70px;
  border: 4px solid #ec9819;
  margin-bottom: 5%;

}

.events-text{
  color: #ca8d2f;
  letter-spacing: 3px;
  font-weight: 800;
}

.events-text-white{
  color: white;
  font-family: 'Geneva', sans-serif;
  margin: 5%;
  font-size: 18px;
}

.events-text-white-more{
  color: white;
  font-family: 'Geneva', sans-serif;
}


/*Community*/
.community{
  background-image: url(../src/img/community/Header-Community-low.webp);
  background-size: cover;
  background-position: center;
  height: 70vh;
  width: 100%;
}

.read-more-link{
  color: #ca8d2f !important;
}

.card-testimonial{
  margin: 30px;
}

.fa-star{
  padding-left: 5px;
}

.name-testimonial{
  letter-spacing: 2px;
}
.carousel-control-prev-icon, .carousel-control-next-icon{
  color:#ca8d2f;
  /*border: 1px solid #ca8d2f;*/
}

/*Retreats*/
.retreats{
  background-image: url(../src/img/retreats/Header-Retreats-low.webp);
  background-size: cover;
  background-position: center;
  height: 70vh;
  width: 100%;
}



.footer {
  padding: 15px;
}

.footer .fa-facebook{
  color: #ca8d2f;
}


.special-border {
  background-image: url(../src/img/services/footerimg.webp);
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
}

.footer-row .fa{
  color: #ca8d2f;
  padding-right: 10px;
}


.footer-row li{
  padding-bottom: 10px;
}

.footer-row, .footer-lowest{
  font-family: 'Geneva', sans-serif; 
}
/* footer .container-fluid {
  padding-left: 150px;
  padding-right: 150px;
} */

@media only screen and (max-width: 600px) {
  .footer .container-nonmobile{
    display: none;
  }

}

@media only screen and (min-width: 600px) {
  .footer .container-mobile{
    display: none;
  }
}

hr{
  background-color: rgb(32, 32, 32);
}

.all-rights{
  letter-spacing: 2px;
}

@media only screen and (min-width: 600px) {
  footer .container-fluid {
    padding-left: 150px;
    padding-right: 150px;
  }
}
.footer-head {
  font-weight: bold;
  text-decoration: underline;
  padding-bottom: 2%;
}

.footer-row a {
  display: grid;
  color: white;
  padding-bottom: 2%;
}

.footer-lowest{
  letter-spacing: 2px;
}
.footer-row .btn {
  border-radius: 0%;
  font-size: 20px;
}

.arrow {
  border: solid white;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 4px;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}
